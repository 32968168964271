import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import Register from "./pages/Register";
import Login from "./pages/Login";
import Write from "./pages/Write";
import Home from "./pages/Home";
import Single from "./pages/Single";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Apply from "./pages/Apply";
import Winners from "./pages/Winners";
import Submitted from "./pages/Submitted";
import Gdpr from "./pages/Gdpr";
import Contact from "./pages/Contact";
import Admin from "./pages/AdminPages/Admin";
import Adminprocess from "./pages/AdminPages/Adminprocess";
import Write2 from "./pages/Write2";
import Lawyer from "./pages/AdminPages/Lawyer";
import Profile from "./pages/Profile";
import Reader from "./pages/AdminPages/Reader";
import ResetPassword from "./pages/ResetPassword";
import AdminRanking from "./pages/AdminPages/AdminRanking";
import DownloadPdf from "./pages/AdminPages/DownloadPdf";
import ErrorElement from "./pages/ErrorElement";
import NotFound from "./pages/NotFound";
import "./style.scss";

const Layout = () => {
  return (
    <>
      <Navbar />
      <Outlet />
      <Footer />
    </>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    // errorElement: <ErrorPage/>,
    children: [
      {
        path: "/",
        element: <Home />,
        errorElement: <ErrorElement />,
      },
      {
        path: "/post/:id",
        element: <Single />,

        errorElement: <ErrorElement />,
      },
      {
        path: "/write",
        element: <Write />,
        errorElement: <ErrorElement />,
      },
      {
        path: "/register",
        element: <Register />,
        errorElement: <ErrorElement />,
      },
      {
        path: "/login",
        element: <Login />,
        errorElement: <ErrorElement />,
      },
      {
        path: "/apply",
        element: <Apply />,
        errorElement: <ErrorElement />,
      },
      {
        path: "/ansok",
        element: <Apply />,
        errorElement: <ErrorElement />,
      },
      {
        path: "/winners",
        element: <Winners />,
        errorElement: <ErrorElement />,
      },
      {
        path: "/pristagare.php",
        element: <Winners />,
        errorElement: <ErrorElement />,
      },
      {
        path: "/Gdpr",
        element: <Gdpr />,
        errorElement: <ErrorElement />,
      },
      {
        path: "/contact",
        element: <Contact />,
        errorElement: <ErrorElement />,
      },
      {
        path: "/kontakt.php",
        element: <Contact />,
        errorElement: <ErrorElement />,
      },
      {
        path: "/admin",
        element: <Admin />,
        errorElement: <ErrorElement />,
      },
      {
        path: "/lawyer",
        element: <Lawyer />,
        errorElement: <ErrorElement />,
      },
      {
        path: "/reader",
        element: <Reader />,
        errorElement: <ErrorElement />,
      },
      {
        path: "/adminprocess",
        element: <Adminprocess />,
        errorElement: <ErrorElement />,
      },
      {
        path: "/adminranking",
        element: <AdminRanking />,
        errorElement: <ErrorElement />,
      },
      {
        path: "/application/:step",
        element: <Write2 />,

        errorElement: <ErrorElement />,
      },
      {
        path: "/profile",
        element: <Profile />,
        errorElement: <ErrorElement />,
      },
      {
        path: "/submitted",
        element: <Submitted />,
        errorElement: <ErrorElement />,
      },
      {
        path: "/resetpassword",
        element: <ResetPassword />,
        errorElement: <ErrorElement />,
      },
      {
        path: "/downloadpdf",
        element: <DownloadPdf />,
        errorElement: <errorElement />,
      },
      // This should be last path
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
]);

function App() {
  return (
    <div className="app">
      <div className="container">
        <RouterProvider router={router} />
      </div>
    </div>
  );
}

export default App;
