import React, { useContext } from "react";
import { PDFViewer, PDFDownloadLink } from "@react-pdf/renderer";
import PdfDocument from "../components/PdfDocument";
import { AuthContext } from "../context/authContext";

const Success = ({ nextStep, handleChange, prevStep, values, handIn }) => {
  const Previous = (e) => {
    e.preventDefault();
    prevStep();
  };
  const { currentUser } = useContext(AuthContext);
  const Continue = (e) => {
    e.preventDefault();
    handIn();
  };

  const confirmHandin = async (e) => {
    e.preventDefault();

    const res = await fetch(
      `${process.env.REACT_APP_BASE_URL}/users/confirmhandin`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).catch((error) => {
      console.log(error);
      return;
    });
  };
  values.email = currentUser.email;
  return (
    <div className="add" style={{ marginBottom: "0px" }}>
      <div style={{ height: "800px" }} className="content" id="valuesSummary">
        <PDFViewer style={{ height: "700px" }}>
          <PdfDocument values={values} />
        </PDFViewer>
        {/* <div> {renderApp}</div> */}
      </div>
      <div className="content">
        <p>
          Kolla noggrant igenom att uppgifterna stämmer innan du skickar in
          ansökan. <br /> Obs! Vi rekommenderar att du{" "}
          <PDFDownloadLink
            style={{ textDecoration: "underline", color: "black" }}
            document={<PdfDocument values={values} />}
            fileName="AnsökanHedberg.pdf"
          >
            {({ _blob, _url, loading, _error }) =>
              loading ? "Loading document..." : "laddar ner"
            }
          </PDFDownloadLink>{" "}
          pdf-filen av din ansökan innan du skickar in din ansökan. Detta
          underlättar felsökning om något blir fel efter att du skickat in.
          Filen ska även signernas och skickas in till je@eslaw.se senast 7
          dagar efter inlämnad ansökan.
        </p>
        <div
          style={{ display: "flex", justifyContent: "center" }}
          className="buttons"
        >
          <button
            className="applicationbutton"
            style={{ marginRight: "2px" }}
            onClick={Previous}
          >
            Tillbaka
          </button>
          <button className="applicationbutton" onClick={Continue}>
            Skicka in ansökan
          </button>

          // TODO
          {/* <button className="applicationbutton" onClick={confirmHandin}>
            Test mail
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default Success;
