import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { useContext } from "react";
import { AuthContext } from "../context/authContext";
import { PDFViewer, PDFDownloadLink } from "@react-pdf/renderer";
import PdfDocument from "../components/PdfDocument";

const Single = () => {
  const [post, setPost] = useState({});

  const { currentUser, stage } = useContext(AuthContext);
  const [applications, setApplications] = useState([]);
  const applicationIndex = applications.map((o) => o.id);
  //const minId = Math.min(...applications.map((o) => o.id));
  const minId = applications?.id;
  //const maxId = Math.max(...applications.map((o) => o.id));
  const maxId = applications[applications.length - 1]?.id;
  // const [grade, setGrade] = useState();
  const [updated, setUpdated] = useState(false);
  const [grade, setGrade] = useState();
  const [mårten, setMårten] = useState();
  const [jörgen, setJörgen] = useState();
  const [lotta, setLotta] = useState();

  //const [stage, setStage] = useState();

  const location = useLocation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { currentData } = state;

  const postId = location.pathname.split("/")[2];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/posts/id/${postId}`
        );
        if (!response.ok) {
          const message = `An error occurred: ${response.statusText}`;
          return;
        }
        const post = await response.json();
        setPost(post);
        if (post.Mårten) {
          setMårten(post.Mårten);
        } else {
          setMårten("");
        }
        if (post.Jörgen) {
          setJörgen(post.Jörgen);
        } else {
          setJörgen("");
        }
        if (post.Lotta) {
          setLotta(post.Lotta);
        } else {
          setLotta("");
        }

        setGrade(post.grade);
        const response2 = await fetch(
          `${process.env.REACT_APP_BASE_URL}/posts/`
        );
        if (!response2.ok) {
          const message = `An error occurred: ${response2.statusText}`;
          return;
        }
        const posts = await response2.json();
        const completedPosts = posts.filter((e) => e.completed === true);
        const compandapprPosts = completedPosts.filter(
          (e) => e.approved === true
        );
        const finalists = compandapprPosts.filter((e) => e.finalist === true);
        const myPosts = compandapprPosts.filter(
          (e) => e.scorer === currentUser.id
        );
        //const shownposts = myPosts.filter((e) => e.approved === true);
        // if (currentUser.role == "lawyer") {
        //   setApplications(completedPosts);
        // } else {
        //   if (stage == 1) {
        //     setApplications(compandapprPosts);
        //   } else if (stage == 2) {
        //     setApplications(myPosts);
        //   } else if (stage == 3) {
        //     setApplications(finalists);
        //   }
        // }
        setApplications(currentData);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [postId]);

  const handleChangeComment = (input) => (e) => {
    e.preventDefault();
    if (input == "Mårten") {
      post.Mårten = e.target.value;
      setMårten((prev) => e.target.value);
    } else if (input == "Lotta") {
      post.Lotta = e.target.value;
      setLotta((prev) => e.target.value);
    } else if (input == "Robin") {
      post.Jörgen = e.target.value;
      setJörgen((prev) => e.target.value);
    }
    postStateToDb(post);
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`/posts/${postId}`);
      navigate("/");
    } catch (err) {
      console.log(err);
    }
  };
  const handleChange = (input) => async (e) => {
    if (post?.grades?.filter((e) => e.user === currentUser?.id).length > 0) {
      post?.grades?.forEach((element) => {
        if (element.user == currentUser.id) {
          element.grade = input.toString();
        }
      });
    } else {
      post.grades = {
        user: currentUser.id,
        grade: input.toString(),
      };
    }
    post.grade = input.toString();
    await postStateToDb(post);
    setUpdated(!updated);
    setPost((prev) => ({ ...prev, grade: input.toString() }));
    setGrade(input.toString());
  };
  const postStateToDb = async (post) => {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/posts`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(post),
    });
    const res = await response.json();
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    postStateToDb(post);
  };

  const getText = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent;
  };

  return (
    <div className="single">
      <div className="content">
        <div className="posts" style={{ width: "50vw" }}>
          {currentUser?.role == "scorer" ? (
            <Link className="link" to="/admin">
              Gå tillbaka
            </Link>
          ) : (
            <Link className="link" to={`/${currentUser?.role}`}>
              Gå tillbaka
            </Link>
          )}

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom: "3vh",
            }}
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <h3 style={{ marginRight: "1vh" }}> Ansökan </h3>
                  <h3 style={{ marginRight: "1vh" }}>
                    {applicationIndex.indexOf(postId) + 1}
                  </h3>
                  <h3 style={{ marginRight: "1vh" }}> av </h3>
                  <h3>{applicationIndex.length}</h3>
                </div>
                <div>
                  {postId == minId ? (
                    <button
                      style={{ margin: "10px", backgroundColor: "gray" }}
                      disabled
                    >
                      {" "}
                      Föregående ansökan
                    </button>
                  ) : (
                    <button
                      style={{ margin: "10px" }}
                      onClick={() =>
                        navigate(
                          `/post/${
                            applicationIndex[
                              applicationIndex.indexOf(postId) - 1
                            ]
                          }`,
                          { state: { currentData } }
                        )
                      }
                    >
                      Föregående ansökan
                    </button>
                  )}
                  {postId == maxId ? (
                    <button disabled style={{ backgroundColor: "gray" }}>
                      {" "}
                      Nästa ansökan
                    </button>
                  ) : (
                    <button
                      onClick={() =>
                        navigate(
                          `/post/${
                            applicationIndex[
                              applicationIndex.indexOf(postId) + 1
                            ]
                          }`,
                          { state: { currentData } }
                        )
                      }
                    >
                      Nästa ansökan
                    </button>
                  )}
                </div>
                {stage == 2 && (
                  <form className="grades">
                    <input
                      value={1}
                      checked={post.grade == 1}
                      name="grade1"
                      style={{ marginLeft: "20px" }}
                      type="radio"
                      onChange={handleChange(1)}
                    />{" "}
                    1
                    <input
                      value={2}
                      checked={post.grade == 2}
                      name="grade2"
                      style={{ marginLeft: "20px" }}
                      type="radio"
                      onChange={handleChange(2)}
                    />{" "}
                    2
                    <input
                      value={3}
                      checked={post.grade == 3}
                      name="grade3"
                      style={{ marginLeft: "20px" }}
                      type="radio"
                      onChange={handleChange(3)}
                    />{" "}
                    3
                    <input
                      value={4}
                      checked={post.grade == 4}
                      name="grade4"
                      style={{ marginLeft: "20px" }}
                      type="radio"
                      onChange={handleChange(4)}
                    />{" "}
                    4
                    <input
                      value={5}
                      checked={post.grade == 5}
                      name="grade5"
                      style={{ marginLeft: "20px" }}
                      type="radio"
                      onChange={handleChange(5)}
                    />{" "}
                    5
                    <input
                      value={0}
                      checked={post.grade == 0}
                      name="grade0"
                      style={{ marginLeft: "20px" }}
                      type="radio"
                      onChange={handleChange(0)}
                    />{" "}
                    Avstå att betygsätta
                    <button
                      onClick={handleSubmit}
                      name="grade"
                      style={{ marginLeft: "20px" }}
                      type="submit"
                    >
                      Spara betyg
                    </button>
                  </form>
                )}
                <div style={{ display: "flex" }}>
                  {" "}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginRight: "5vh",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <div>
                        <h3>Cv: </h3>
                        {post.cv && (
                          <a href={`${post?.cv}`} alt="">
                            {post.cv
                              ?.substring(56, post.cv.length - 35)
                              .split("%20")
                              .join(" ")
                              .split("%C3%83%C2%B6")
                              .join("ö")
                              .split("%C3%83%C2%85")
                              .join("å")
                              .split("a%C3%8C%C2%88")
                              .join("ä")
                              .split("%C3%83%C2%A5")
                              .join("å")
                              .split("%C3%83%C2%96")
                              .join("ö")
                              .split("%C3%83%C2%A4")
                              .join("ä")}
                          </a>
                        )}
                        <h3>Forskningsprogram:</h3>
                        {post.researchprog && (
                          <a href={`${post?.researchprog}`} alt="">
                            {post.researchprog
                              ?.substring(56, post.researchprog.length - 35)
                              .split("%20")
                              .join(" ")
                              .split("%C3%83%C2%B6")
                              .join("ö")
                              .split("%C3%83%C2%85")
                              .join("å")
                              .split("a%C3%8C%C2%88")
                              .join("ä")
                              .split("%C3%83%C2%A5")
                              .join("å")
                              .split("%C3%83%C2%96")
                              .join("ö")
                              .split("%C3%83%C2%A4")
                              .join("ä")}
                          </a>
                        )}
                      </div>
                      <div style={{ marginLeft: "10px" }}>
                        <h3>Publikationer:</h3>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          {Array.isArray(post.publications) &&
                            post.publications.map((item, i) => (
                              <a key={item} style={{}} href={item}>
                                {item
                                  ?.substring(56, item.length - 35)
                                  .split("%20")
                                  .join(" ")
                                  .split("%C3%83%C2%B6")
                                  .join("ö")
                                  .split("%C3%83%C2%85")
                                  .join("å")
                                  .split("a%C3%8C%C2%88")
                                  .join("ä")
                                  .split("%C3%83%C2%A5")
                                  .join("å")
                                  .split("%C3%83%C2%96")
                                  .join("ö")
                                  .split("%C3%83%C2%A4")
                                  .join("ä")}
                              </a>
                            ))}
                        </div>
                      </div>
                      <div style={{ marginLeft: "10px" }}>
                        <h3>Kommentarer:</h3>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <div style={{ display: "flex" }}>
                            <div style={{ width: "65px" }}>Lotta: </div>
                            {currentUser?.firstname == "Lotta" ? (
                              <input
                                value={lotta}
                                onChange={handleChangeComment("Lotta")}
                                type="text"
                              />
                            ) : (
                              <input
                                disabled
                                value={lotta}
                                onChange={handleChangeComment("Lotta")}
                                type="text"
                              />
                            )}
                          </div>

                          <div style={{ display: "flex" }}>
                            <div style={{ width: "65px" }}>Robin: </div>
                            {currentUser?.firstname == "Robin" ? (
                              <input
                                value={jörgen}
                                onChange={handleChangeComment("Robin")}
                                type="text"
                              />
                            ) : (
                              <input
                                disabled
                                value={jörgen}
                                onChange={handleChangeComment("Jörgen")}
                                type="text"
                              />
                            )}
                          </div>
                          <div style={{ display: "flex" }}>
                            <div style={{ width: "65px" }}>Mårten: </div>
                            {currentUser?.firstname == "Mårten" ? (
                              <input
                                value={mårten}
                                onChange={handleChangeComment("Mårten")}
                                type="text"
                              />
                            ) : (
                              <input
                                disabled
                                value={mårten}
                                onChange={handleChangeComment("Mårten")}
                                type="text"
                              />
                            )}
                          </div>
                        </div>
                      </div>{" "}
                      {/* <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "flex-end",
                          marginLeft: "5px",
                        }}
                      >
                        <button
                          style={{
                            height: "25px",
                            padding: "4px 4px",
                            borderRadius: "4px",
                          }}
                          onClick={saveComment}
                        >
                          Spara kommentar
                        </button>
                      </div> */}
                    </div>{" "}
                  </div>
                </div>
                <div style={{ textAlign: "center" }}>
                  <h3>Titel:</h3>
                  <p>{post.title}</p>
                  <h3>Huvudsökande:</h3>
                  <p>
                    {post.jobtitle} {post.name}
                  </p>
                  <h3>Prefekt:</h3>
                  <p>{post.prefect}</p>
                  <h3>Medsökande:</h3>
                  {post.coauthor?.map((item, i) => (
                    <>
                      <p key={i}>
                        {item.title}, {item.name}
                      </p>
                    </>
                  ))}
                  <h3>Kontaktuppgifter:</h3>
                  <p>Email: {post.email}</p>
                  <p>Telefon: {post.phone}</p>
                  <p>Adress:</p>
                  <p>{post.institution}</p>
                  <p>{post.university}</p>
                  <p>{post.address}</p>
                  <p>{post.city}</p>
                  <h3>Hypotes:</h3>
                  <p>{post.hypothesis}</p>
                  <h3>Projektplan:</h3>
                  <p>{post.desc}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Single;
