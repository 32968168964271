import { React, useContext, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/authContext";
import AllPdfDocument from "../../components/AllPdfDocument";
import { PDFDownloadLink } from "@react-pdf/renderer";

const DownloadPdf = () => {
  const { currentUser, stage } = useContext(AuthContext);
  const [currentUserReady, setCurrentUserReady] = useState() || null;
  const [approvedApplications, setApprovedApplications] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser && currentUser?.role == "user") {
      navigate("/");
    }
    if (currentUser && stage) {
      setCurrentUserReady(true);
    }
  }, [currentUser]);

  const today = new Date();
  const month = today.getMonth() + 1;

  useEffect(() => {
    const fetchData = async () => {
      try {
        let year = today.getFullYear();
        if (month > 7) {
          year = today.getFullYear() + 1;
        }
        let response;
        let res2;
        Promise.all([
          (response = await fetch(`${process.env.REACT_APP_BASE_URL}/posts/`)),
          (res2 = await fetch(`${process.env.REACT_APP_BASE_URL}/stage/`)),
        ]);

        if (!response.ok) {
          const message = `An error occurred: ${response.statusText}`;
          return;
        }
        let posts;
        let response2;
        Promise.all([
          (posts = await response.json()),
          (response2 = await res2.json()),
        ]);

        // posts.myfinalgrade = posts.finalgrades.grade;
        const thisYearPosts = posts.filter((e) => e.year == year);
        const completedPosts = thisYearPosts.filter(
          (e) => e.completed === true
        );
        const approvedPosts = completedPosts.filter((e) => e.approved === true);

        const myPosts = approvedPosts.filter(
          (e) => e.scorer === currentUser?.id
        );

        const finalists = approvedPosts.filter((e) => e.finalist === true);
        finalists.map((e) => {
          e.finalgrades.map((element) => {
            if (element.user == currentUser?.id) {
              e.myfinalgrade = element.grade;
            }
          });
        });
        finalists.map((e) => {
          e.grades.map((element) => {
            if (element.user == currentUser?.id) {
              e.mygrade = element.grade;
            }
          });
        });
        if (response2.stage == 1) {
          setApprovedApplications(approvedPosts);
        } else if (response2.stage == 3) {
          setApprovedApplications(finalists);
        } else {
          setApprovedApplications(myPosts);
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [currentUserReady]);

  return (
    <div
      className="table_container"
      style={{ display: "flex", justifyContent: "center" }}
    >
      <div style={{ marginTop: "5rem" }}>
        <PDFDownloadLink
          style={{
            textDecoration: "underline",
            color: "black",
            marginRight: "2rem",
          }}
          document={<AllPdfDocument values={approvedApplications} />}
          fileName="AnsökanHedberg.pdf"
        >
          {({ _blob, _url, loading, _error }) =>
            loading ? (
              <button>Laddar</button>
            ) : (
              <button>Ladda ner pdf av samtliga</button>
            )
          }
        </PDFDownloadLink>
      </div>
    </div>
  );
};

export default DownloadPdf;
